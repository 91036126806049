export default defineI18nConfig(() => {
    return {
        legacy: false,
        locales: ['kk', 'en', 'ru'],
        defaultLocale: 'kk',
        messages: {
            kk: {
                welcome: 'Қош келдіңіз',
                titleIndexFaq: 'Сұрақтар және жауаптар',
            },
            en: {
                welcome: 'Welcome',
                titleIndexFaq: 'Q&A',
            },
            ru: {
                welcome: 'Добро пожаловать',
                titleIndexFaq: 'Вопросы и ответы',
                documents: {
                    pageTitle: 'Документы',
                    contractTitle: 'Договоры',
                    contractDescription: [
                        'Осуществлять перевозки через Компанию «ПЭК» можно без оформления договора, подписанного обеими сторонами. В таких случаях действует договор, размещенный на нашем сайте в свободном доступе.',
                        'В соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте.',
                        'Акцепт договора осуществляется путем заказа услуг и/или сдачи груза и/или оплаты услуг и/или получения груза.',
                        'По желанию клиента может быть заключён типовой договор на транспортно-экспедиционное обслуживание (демо текст).',
                    ],
                    contractListTitleLegal: 'Для юридических лиц',
                    contractListTitleIndividual: 'Для физических лиц',
                    deliveryTitle: 'Документы на получение и отправку',
                    deliveryDescription: [
                        'Осуществлять перевозки через Компанию «ПЭК» можно без оформления договора, подписанного обеими сторонами. В таких случаях действует договор, размещенный на нашем сайте в свободном доступе (демо текст).',
                        "Ознакомьтесь <a href='#'>с перечнем грузов</a> подлежащих обязательной жесткой упаковке.",
                    ],
                    tarifTitle: 'Тарифы на перевозку',
                    tarifDescription: [
                        'Осуществлять перевозки через Компанию «ПЭК» можно без оформления договора, подписанного обеими сторонами. В таких случаях действует договор, размещенный на нашем сайте в свободном доступе (демо текст)',
                    ],
                },
            },
        },
    };
});
